import {
  Box,
  Paper,
  TextField,
  Typography,
  Table,
  TableCell,
  Button,
  TableRow,
  TablePagination,
  Radio,
} from "@mui/material";
import { Grid } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AssignmentIcon from "@mui/icons-material/Assignment";
import React, { useEffect, useState } from "react";
import { getToken } from "../../services/LocalStorageService";
import axios from "axios";
import { API_BASE_URL } from "../../views/constants";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const FileDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [comment, setComment] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    console.log(comment)
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRadioChange = (name) => {
    setSelected(name);
    console.log(selected)
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatDate = (date) => {
    try {
      if (date) {
        const parsedDate = new Date(date);
        return format(parsedDate, "MM/dd/yyyy");
      }
      return "";
    } catch (error) {
      return "";
    }
  };
  useEffect(() => {
    const token = getToken();
    async function getAllFilesSass() {
      try {
        const files = await axios.get(
          `${API_BASE_URL}/api/data/sas/files-details/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          }
        );
        console.log(files.data);
        setDetails(files.data);
      } catch (error) {
        console.log(error);
      }
    }
    getAllFilesSass();
  }, []);

  const handleAssign = async (
    comment,
    id,
    selected,
    selectedDate,

  ) => {
    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token.access_token}`,
    };

    try {
      const response = await axios.patch(
        `${API_BASE_URL}/api/data/sas/assign/${selected}/`,
        {
          assigned_reviewer_fk: id,
          file_fk: selected,
          due_date:selectedDate,
          assign_comment:comment
        },
        { headers }
      );
      console.log(response.data);
      navigate("/code_assessment")

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const token = getToken();
    async function getAllUsers() {
      try {
        const users = await axios.get(`${API_BASE_URL}/api/users/profiles/`, {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        });

        const Reviewers = users.data.filter(user => user.profile === 'Reviewer');
        console.log(Reviewers)
        setUsers(Reviewers);

      } catch (error) {
        console.log(error);
      }
    }
    getAllUsers();
  }, []);


  const fileDetailsList = [
    {
      label: (
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          Name
        </Typography>
      ),
      value: (
        <Box
          style={{
            backgroundColor: "#ffe600",
            borderRadius: "8px", // Adjust the border-radius here
            padding: "8px", // Adjust the padding for the background
          }}
        >
          <Typography
            variant="body1"
            style={{
              color: "black",
              maxWidth: "200px", // Set your desired max width
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block", // or "block" depending on your layout
            }}
            title={details.filename} // This will show the full filename on hover
          >
            {details.filename}
          </Typography>

        </Box>
      ),
      icon: (
        <FileOpenIcon
          style={{
            backgroundColor: "#ffe600",
            color: "black",
            borderRadius: "50%",
            fontSize: "2.5rem",
            padding: "10px",
          }}
        />
      ),
    },
    {
      label: (
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          Upload Date
        </Typography>
      ),
      value: (
        <Box
          style={{
            backgroundColor: "#ffe600",
            borderRadius: "8px", // Adjust the border-radius here
            padding: "8px", // Adjust the padding for the background
          }}
        >
          <Typography variant="body1" style={{ color: "black" }}>
            {formatDate(details.upload_date)}
          </Typography>
        </Box>
      ),
      icon: (
        <FileUploadIcon
          style={{
            backgroundColor: "#ffe600",
            color: "black",
            borderRadius: "50%",
            fontSize: "2.5rem", // Adjust the font size here
            padding: "10px", // Add padding for a larger background
          }}
        />
      ),
    },
    {
      label: (
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          File Status
        </Typography>
      ),
      value: (
        <Box
          style={{
            backgroundColor: "#ffe600",
            borderRadius: "8px", // Adjust the border-radius here
            padding: "8px", // Adjust the padding for the background
          }}
        >
          <Typography variant="body1" style={{ color: "black" }}>
            {details.file_status}
          </Typography>
        </Box>
      ),
      icon: (
        <PublishedWithChangesIcon
          style={{
            backgroundColor: "#ffe600",
            color: "black",
            borderRadius: "50%",
            fontSize: "2.5rem", // Adjust the font size here
            padding: "10px", // Add padding for a larger background
          }}
        />
      ),
    },
    {
      label: (
        <Typography
          variant="subtitle1"
          gutterBottoms
          style={{ fontWeight: "bold", color: "black" }}
        >
          Complexity
        </Typography>
      ),
      value: (
        <Box
          style={{
            backgroundColor: "#ffe600",
            borderRadius: "8px", // Adjust the border-radius here
            padding: "8px", // Adjust the padding for the background
          }}
        >
          <Typography variant="body1" style={{ color: "black" }}>
            {details.complexity}
          </Typography>
        </Box>
      ),
      icon: (
        <AccountTreeIcon
          style={{
            backgroundColor: "#ffe600",
            color: "black",
            borderRadius: "50%",
            fontSize: "2.5rem", // Adjust the font size here
            padding: "10px", // Add padding for a larger background
          }}
        />
      ),
    },
  ];
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Paper elevation={3} style={{ padding: "16px" }}>
        <Typography variant="h5">File Details</Typography>

        <Grid
          container
          spacing={2}
          style={{ display: "flex", flexDirection: "row" }}
        >
          {fileDetailsList.map((item, index) => (
            <Grid item xs={3} key={index}>
              <Box
                style={{
                  backgroundColor: "#ffe600",
                  borderRadius: "8px",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {item.icon}
                {item.label}
                {item.value}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper elevation={3} style={{ padding: "16px" }}>
        <Typography variant="h5">Team leaders' List</Typography>

        <Table className="no-wrap mt-3 align-middle" responsive borderless>
          <thead>
            <tr>
              <th></th>
              <th style={{ marginLeft: "20px" }}>Name</th>
              <th style={{ textAlign: "center" }}>Expertise</th>

              <th style={{ textAlign: "center" }}>Number of Scripts</th>
              <th style={{ textAlign: "center" }}>Estimated Deadline</th>

              <th style={{ textAlign: "center" }}>Team leader</th>
            </tr>
          </thead>
          <tbody>
            {users
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((tdata, index) => (
                <tr
                  key={
                    tdata.id
                  }
                  className="border-top"
                >
                  <td>
                    {" "}
                    <Radio
                      checked={selected === tdata.email}
                      onChange={() => handleRadioChange(tdata.email)}
                    />
                  </td>
                  <td>{tdata.first_name}{''}{tdata.last_name}</td>
                  <td style={{ textAlign: "center" }}>{tdata.expertise}</td>
                  <td style={{ textAlign: "center" }}>
                    <h6>{tdata.numOfScripts}</h6>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {tdata.estimatedDeadline}
                  </td>
                  <td style={{ textAlign: "center" }}>{tdata.Reviewer}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-displayedRows": {
              marginTop: "16px",
            },
            ".MuiTablePagination-selectLabel": {
              marginTop: "16px",
            },
          }}
        />

      </Paper>

      <Paper elevation={3} style={{ padding: "16px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker label="Select Deadline"
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            sx={{
              width: '100%', // Set the width to 100% or adjust it as needed
              marginBottom: 1, // Adjust the margin-bottom as needed
            }}
          />
        </LocalizationProvider>
        <Typography variant="h5">Comments</Typography>
        <TextField
          id="outlined-multiline-static"
          label="Add your comment here"
          multiline

          variant="outlined"
          fullWidth
          onChange={handleCommentChange}
        />
      </Paper>
      <Paper elevation={3} style={{ padding: "16px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Link to="/code_assessment">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIosNewIcon />}
              style={{
                fontSize: "small",
                color: "black",
                borderColor: "black",
                fontWeight: "bold",
              }}

            >
              Back
            </Button></Link>

          <Button
            variant="contained"
            color="primary"
            endIcon={<AssignmentIcon />}
            style={{
              fontSize: "medium",
              backgroundColor: "#ffe600",
              color: "#000000",
              fontWeight: "bold",
            }}
            onClick={() => handleAssign(comment, selected, id, selectedDate)}
          >
            Assign
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default FileDetails;
