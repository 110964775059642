
const storeToken = (value) => {
    if (value) {
        // console.log("Store Token")
        const { access, refresh } = value;
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
    }
};

const getToken = () => {
    // eslint-disable-next-line camelcase
    let access_token = localStorage.getItem('access_token');
    // eslint-disable-next-line camelcase
    let refresh_token = localStorage.getItem('refresh_token');
    return { access_token, refresh_token };
};

const removeToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
};

export { storeToken, getToken, removeToken };
